// put any variables to override bootstrap here, or can custom remove importing parts of bootstrap

$info: lightblue;

$theme-colors: (
	"peter-blah": green,
);

$enable-rounded: true;
$enable-transitions: true;

$font-size-base: 16;

// $grid-breakpoints: (
//   xl: 1300px,
// );

// $container-max-widths: (
//   xl: 1240px,
// );

// make default container actually be wider
$grid-breakpoints: (
	xs: 0,
  sm: 576px,
  md: 768px,
	lg: 992px,
	xl: 1200px,
  xll: 1400px
	);

	$container-max-widths: (
		sm: 540px,
		md: 720px,
		lg: 960px,
		xl: 1140px,
		xll: 1340px
	);










@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
// @import "~bootstrap/scss/nav";
// @import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";