.data-event-container {
	padding: 10px;
	.data-event-info, .raw-images {
		margin-top: 30px;
		min-height: 550px;
		border: 1px solid #eee;
		border-radius: 2px;
		padding: 10px 15px;
		box-shadow: 0 7px 14px 0 rgba(60,66,87, 0.1), 0 3px 6px 0 rgba(0, 0, 0, .07);

		img {
			max-width: 100%;
		}
	}

	.raw-image {
		margin-bottom: 30px;
	}




}