.register-container {
	padding-top: 50px;

	.alert-container {
		min-height: 85px;
	}

	.alert {
		max-width: 700px;
		padding-right: 50px; // necessary to fix weird bootstrap bug..?
		margin: auto;
	}

	.form-group {
		margin-bottom: 30px;
	}

	form {
		max-width: 400px;
		margin: auto;
	}

	.register-wrapper {
		max-width: 400px;
		margin: auto;
		margin-top: 40px;
		font-style: italic;
	}

	button.register-button {
		min-width: 120px;
		span {
			margin-right: 10px;
		}
	}

}

