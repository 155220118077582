@import "styles/base.scss";

.navbar-container {
	max-width: 1500px;
	display: flex;
}

nav.navbar {
	background: $dark-blue;
	height: 50px;
	display: flex;
	align-items: center;
	color: #ccc;

	.navbar-logo {
		font-size: 20px;
		display: inline-block;
	}

	.navbar-container {
		align-items: center;
	}

	.navbar-content {
		margin-left: 30px;
		display: inline-flex;
		align-items: center;
		justify-content: space-between;
		flex-grow: 200;
	}

	// have left content take up all of remaining space
	.content-left {
		flex-grow: 1;
		// background: red;
	}

	.content-right {
		display: flex-inline;
	}

	a {
		color: #ccc;
		&.active {
			color: white;
		}
	}












}





// <Navbar bg={navbarTheme} variant={navbarTheme} expand="lg">
// -                       <Navbar.Brand href="#home">ZebraSafety</Navbar.Brand>
// -                       <Navbar.Toggle aria-controls="basic-navbar-nav" />
// -                       <Navbar.Collapse id="basic-navbar-nav">
// -                               <Nav className="mr-auto">
// -
// -                                       <LinkContainer to="/" exact activeClassName="active">
// -                                               <Nav.Link>Homee</Nav.Link>
// -                                       </LinkContainer>
// -
// -                                       <LinkContainer to="/about">
// -                                               <Nav.Link>About</Nav.Link>
// -                                       </LinkContainer>
// -
// -
// -                                       <LinkContainer to="/contact">
// -                                               <Nav.Link>Contact</Nav.Link>
// -                                       </LinkContainer>
// -                               </Nav>
// -                               <Form inline>
// -                                       <FormControl type="text" placeholder="Search" className="mr-sm-2" />
// -                                       <Button variant="outline-success">Search</Button>
// -                               </Form>
// -                       </Navbar.Collapse>
// -               </Navbar>
// -       );