@import "styles/base.scss";

.camera-container {
	// background: gold;
	margin-top: 10px;
	padding: 10px;


	// "data container" is container dashboard thing
	// trying to copy design of stripe dashboard
	.data-container {
		border: 1px solid #eee;
		border-radius: 2px;
		min-height: 600px;
		padding: 10px 15px;
		// background: blue;
		box-shadow: 0 7px 14px 0 rgba(60,66,87, 0.1), 0 3px 6px 0 rgba(0, 0, 0, .07);
	}

	.data-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 10px;
		border-bottom: 1px solid #eee;
	}

	.items {
		flex-grow: 1;
	}

	.time-presets {
		span {
			color: #888;
			padding: 0 1px;
			width: 35px;
			display: inline-block;
			cursor: pointer;
			text-align: center;
			&.selected {
				color: $dark-blue;
				font-weight: bold;
			}
			&:hover {
				color: #444;
			}
		}
	}


	.items-right {
		text-align: right;
	}

	.react-datepicker-wrapper input {
		max-width: 150px;
		font-size: 14px;
	}

	span.spacer {
		width: 80px;
		display: inline-block;
	}

	button.toggle-button {
		position: relative;
		top: -2px;
		margin-left: 50px;
		width: 140px;
		// height: 24px;
	}

	.date-arrow {
		margin: 0px 10px;
		svg {
			font-size: 30px;
			position: relative;
			top: 5px;
		}
	}

	.stats-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 12px 50px 10px;
		border-bottom: 1px solid #eee;
		margin-bottom: 20px;

		h5 {
			font-size: 12px;
			font-weight: normal;
			color: #555;
			text-transform: uppercase;
		}
		span {
			font-weight: bold;
		}
	}

	.data-body {
		padding-top: 10px;
	}

	.data-event-card {
		padding: 10px;
		display: flex;
		flex-direction: column;
		height: 300px;
		margin-bottom: 20px;
		cursor: pointer;
		img {
			max-width: 350px;
			max-height: 200px;
		}

		.body {
			flex-grow: 1;
			&.hide {
				display: none;
			}
		}

		.event-date {
			border-top: 1px solid #eee;
		}
	}

	.image-view-body {
		p {
			margin-top: 5px;
		}
	}

	// loading container for data events
	.loading-container {
		// background: lightblue;
		margin-top: 50px;
		text-align: center;
		p {
			font-size: 20px;
			margin-bottom: 20px;
		}
		img {
			width: 150px;
		}
	}
}

