.home-container {
	max-width: 1200px;

	.card {
		margin-bottom: 15px;
		cursor: pointer;
	}



}