// variables
$dark-blue: #21313F;


h1, h2, h3, h4, h5, h6 {
	text-align: center;
}

html, body, #root, .App, .dashboard {
	// this will throw off the infinite scroll calculations of window offset and whatnot
	// if needed, consider using min-height or something
	// height: 100%;
}

// remove weird bootstrap styling
a:focus {
	outline: none;
}

button[disabled] {
	cursor: not-allowed;
}


// make bootstrap form invalid messages appear, but absolute so they don't jump content down
.invalid-feedback {
	display: block;
	position: absolute;
}

.hidden {
	display: none;
}